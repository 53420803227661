import React from "react";
import axios from "axios";
import "./modal.css";

//Delete URL
// import { DeleteUserAdminURL, DeleteKidAdminURL } from "../../config/api";

//delete method
const handleDeleteMember = async (memberID, memberType) => {
  // let deleteMemberUrl;
  // let options = {};

  // if (memberType === "user") {
  //   deleteMemberUrl = DeleteUserAdminURL;
  //   options = { data: { userId: memberID } };
  // } else if (memberType === "kid") {
  //   deleteMemberUrl = DeleteKidAdminURL;
  //   options = { data: { kidId: memberID } };
  // } else console.log("Member type not defined");

  // await axios
  //   .delete(deleteMemberUrl, options)
  //   .then(() => {
  //     memberType === "user"
  //       ? (window.location.href = "/users")
  //       : (window.location.href = "/kids");
  //   })
  //   .catch((err) => {
  //     console.log("DeleteMember Error :", err);
  //     memberType === "user"
  //       ? (window.location.href = "/users")
  //       : (window.location.href = "/kids");
  //   });
};

const Modal = (props) => {
  const {
    setShowModal,
    memberType,
    memberID,
    modalTitle,
    firstBtn,
    secondBtn, onConfirmationDelete
  } = props;

  return (
    <>
      <div className="modal_container">
        <div className="modal_wrapper">
          <div className="modal_title">
            <h4> {modalTitle}</h4>
          </div>
          <div className="modal_buttons">
            <button
              className="modal_btn delete"
              onClick={() => onConfirmationDelete()}
            >
              {firstBtn}
            </button>
            <button
              className="modal_btn cancel"
              onClick={() => setShowModal(false)}
            >
              {secondBtn}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
