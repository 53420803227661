import React from "react";

import ssLeftArrow from "../../assets/Images/arrow-l.png";
import ssRightArrow from "../../assets/Images/arrow-r.png";

const CustomButtonGroupAsArrows = ({ next, previous }) => {
  return (
    <div className="screenshots_bottom">
      <span className="screenshots_arrow" onClick={previous}>
        <img src={ssLeftArrow} alt="ssLeftArrow" />
      </span>
      <span className="screenshots_arrow" onClick={next}>
        <img src={ssRightArrow} alt="ssRightArrow" />
      </span>
    </div>
  );
};

export default CustomButtonGroupAsArrows;
