import React from "react";
import { NavLink } from "react-router-dom";
import "./legalPage.css";

//components
import Footer from "../../components/Footer/Footer";
import Gift from "../../components/Gift/Gift";
import Navbar from "../../components/Navbar/Navbar";

const LegalPrivacy = () => {
  return (
    <>
      <div className="legal_container">
        <Navbar legal={true} />
        <div className="legal_wrapper">
          <div className="legal_header_section">
            <div className="legal_header">
              <div className="legal_title">Legal</div>
              {/* <div className="legal_subtitle">Effective Day 1 May 2022</div> */}
            </div>
            <div className="legal_header_nav">
              <div className="legal_nav_item">
                <NavLink to="/terms">Terms of use</NavLink>
              </div>
              <div className="legal_nav_item">
                <NavLink to="/privacy">Privacy Policy</NavLink>
              </div>
            </div>
          </div>
          <div className="legal_body">
            <div className="legalBody_wrapper">
              <div className="legalBody_area">
                Hushbunny Inc. is the parent company owning and operating the website https://hushbunny.com/ and mobile app hushbunny as https://apps.apple.com/us/app/hushbunny/id1633444164 on App Store, and https://play.google.com/store/apps/details?id=com.hushbunny.app on Google Play Console.
                <br />
                <br />
                Hushbunny is the family storyteller we all so badly missed, to build memory lanes of your kids today, to be inherited and cherished by them when they grow up. Our products include our iOS and Android apps, and our website (our “Products”). Any parent can create a profile of a kid, invite another parent, when both parents add on the kid, they become equal admins of the kid profile, add moments and build a memory lane of their kid together.
                <br />
                <br />
                We will use Hushbunny Inc., Hushbunny.com, and hushbunny interchangeably in this privacy policy and overall across hushbunny properties, and they would mean the same.
                <br />
                <br />
                <span>Hushbunny Inc. </span>understands the importance of your personal privacy. Therefore, we have created this Privacy Policy so that you know how we use and disclose your information when you make it available to us. The Privacy Policy below discloses our practices regarding information collection and usage website located at https://hushbunny.com/privacy, the related mobile website, and the mobile application (collectively, the “Websites”) and the service provided thereon (the “Service”).
                <br />
                <br />
                Hushbunny.com, its corresponding mobile app across operating systems, mobile website, and all apps/sites related to Hushbnny.com constitute the Site Name (part of Hushbunny Inc.), for which this privacy policy and its terms are unequivocally applicable.
                <br />
                <br />
                By using or accessing the Websites and the Service, you signify your agreement to be bound by this Privacy Policy. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY YOU MAY NOT ACCESS OR OTHERWISE USE OUR SERVICE OR PARTICIPA­­TE IN OUR SERVICES.
                <br />
                <br />
                Key aspects of our privacy practices described in this Privacy
                Policy include the following explanations:
                <br />
                <br />
                • The information we collect and why we collect it;
                <br />
                • How we use that information;
                <br />
                • How we share information; and
                <br />
                • The choices we offer.
                <br />
                <br />
                <span>Disclosing the types of of personal and sensitive data that hushbunny app accesses: Personally Identifiable Information That We Collect
                </span>
                <br />
                <br />
                We may ask you for, or you may voluntarily submit, personally identifiable information when you are using the Service. The personally identifiable information which you may provide to us could include, but is not limited to:
                <br />
                <br />
                · Account Data (your information, your kids’ information, your
                spouse’s information)
                <br />
                . Your name, email, phone, Date of Birth. Same for your kids and
                spouse/s
                <br />
                · Your contact information (including, without limitation,
                address and email address);
                <br />
                · Your IP address; and
                <br />
                · Other personal information.
                <br />. Device information: We collect details such as operating system, geographic locations, ISP details, browser types, language, time zones, device identifiers, time zones, IP addresses and other similar details that tell us more about detailed device details of our users.
                <br />
                . Information from third-party partners when we jointly offer any services.
                <br />
                . Profile picture (current and previous pictures)
                <br />
                . Interests, social media and contacts
                <br />. All details you fill on Hushbunny.com across profiles and profile pages (including kid pages)
                <br />
                <br />
                We will use this information to provide personalized experience, to display advertisements to you based on your information and profile, and curate our products and services to your needs. We won’t share your personal information with third parties, but our advertisers, service providers and others will be free to target you with a broad range of dataset they choose as the target audience to display their advertisements and if you come in that funnel.
                {/* <a
                  href="/deleteaccount"
                  _blank="true"
                  style={{ textDecoration: "underline", color: "#0066d6" }}
                >
                  hushbunny.com/deleteaccount
                </a> URL, which once deleted can’t be retrieved. */}
                <br />
                <br />
                <span>Deleting your accounts- Deletion policy</span>
                <br />
                <br />
                Users who have created their accounts on Hushbunny can delete their accounts and related data on our website accessing the <a
                  href="/deleteaccount"
                  _blank="true"
                  style={{ textDecoration: "underline", color: "#0066d6" }}
                >
                  hushbunny.com/deleteaccount
                </a> URL, which once deleted can’t be retrieved. All the data will remain on servers for a maximum 90 days after deletion and then permanently eliminated and deleted from our systems.
                <br />
                <br />
                <span>
                  Non-Personal Or Aggregate Information That We Collect
                </span>

                <br />
                <br />
                When you access our Service, we may automatically collect non-personally identifiable information from you, such as IP host address, web pages viewed, browser type, operating system, referring service, search information, device type, page views, usage and browsing habits on the Service and similar data. We may also aggregate demographic information collected from our users (such as the number of users in a particular geographical location) in a manner which does not identify any one individual. We may also aggregate information collected offline in connection with the Service, obtain non-personally identifiable information from third party sources and develop aggregate information by anonymizing previously collected personally identifiable information. We may also access your information from third parties such as service providers, social media information or other vendors and/or third parties which might not be explicitly mentioned here.
                <br />
                <br />
                We store your information, information related to your kids, your spouse/s, all the moments posted, images, videos, text, multimedia, the personal information provided when populate profiles (self, kids, spouse/s), clicks, navigations, messages sent/received, other communications, contacts, log data, device information, cookie information among other things we collect, store and save which might not be explicitly mentioned here. All current and deleted multimedia and information is retained for us in accordance with our data retention policies.
                <br />
                <br />
                We may also use your information to personalize our products, run analytics, make personalized recommendations, register/verify your accounts, improve our products and services, improve our advertising platforms and advertising/marketing campaigns, analyze efficiency of our advertising and marketing campaigns, law enforcement needs, report/block violation of our terms of service. We may also use your information in other avenues not explicitly mentioned here.
                <br />
                <br />
                It is possible at times when collecting non-personally
                identifiable information through automatic means that we may
                unintentionally collect or receive personally identifiable
                information that is mixed in with the non-personally
                identifiable information. While we will make reasonable efforts
                to prevent such incidental data collection, the possibility
                still exists. If you believe that we have inadvertently
                collected your personal information, please notify us at{" "}
                <a
                  href="mailto:contact@hushbunny.com"
                  style={{ textDecoration: "underline", color: "#0066d6" }}
                >
                  contact@hushbunny.com
                </a>
                <br />
                <br />
                <span>Information Usage- How do we use your data?</span>
                <br />
                <br />
                We will only use your personally identifiable information as described below, unless you have specifically consented to another type of use, either at the time the personally identifiable information is collected from you or through some other form of consent from you or notification to you:
                <br />
                <br />
                ·We may share your personally identifiable information collected in connection with providing the Service.
                <br />
                ·We may use your information and contact lists to invite new users, connect to existing users. Users may also invite, add each-other through email or phone number information, as in any other familiar social networks
                <br />
                .We may use your personally identifiable information to respond to your inquiries or requests.
                <br />
                ·We may use your personally identifiable information to send you emails from time to time about our services, but we will not provide your personally identifiable information to third parties for them to contact you directly unless otherwise permitted by this Privacy Policy or you provide your consent.
                <br />
                ·We may share your personally identifiable information with third parties (collectively, the “Third Party Vendors”) to further the purpose for which you provided such information to us. For example, we may share your information with Elastic Email, for the purpose of sending emails.
                <br />
                .Other users on Hushbunny.com will be able to find you using
                your phone number or email address, and your profile will be
                displayed if it matches your phone number/email/other mechanism
                we have to match profiles
                <br />
                .You will get invites, messages, emails, and other
                communications from other users by putting in your email, phone
                number, and other mechanisms. You will get the option to
                report/block the users and we will to be utmost best put tools
                in place to block/deactivate such users, still we can’t
                guarantee that you won’t receive any communications from those
                users nor can we guarantee complete blockage of communications
                from such users to reach you on Hushbunny.com. Hushbunny.com
                would not be considered even remotely liable for such
                communications, our intentions are to best serve or users, and
                we will continually keep on trying to improve our products and
                services to block/deactivate such users (though without any
                specific timelines)
                <br />
                .We may transfer your data out of your selected country to other
                geographies through our third-party cloud storage providers that
                store data on our behalf in the United States and may be in
                other geographies. We might also use different storage providers
                in different geographies. When you use our products and
                services, we may move data to other countries, geographies,
                however will take measures to protect confidentiality, though
                this can not be guaranteed. Also privacy laws, protections,
                rights may vary in such countries where your data could be
                moved, migrated, transferred etc. and might not be equivalent to
                your rights in your home countries. You abide by these changes
                when you use our products and services
                <br />
                <br />
                <span>
                  We urge you to read the privacy practices of all of our Third
                  Party Vendors before submitting any personally identifiable
                  information through the Service.
                </span>
                <br />
                <br />
                ·We may disclose personally identifiable information as required
                by law or legal process.
                <br />
                ·We may disclose personally identifiable information to
                investigate suspected fraud, harassment or other violations of
                any law, rule or regulation, or the terms or policies for our
                services or our sponsors.
                <br />
                ·We may transfer your personally identifiable information in
                connection with the sale or merger or change of control of
                Hushbunny.com or the division responsible for the services with
                which your personally identifiable information is associated.
                <br />
                ·We may share your personally identifiable information with an
                affiliate of Hushbunny.com who is in the same corporate family
                as us as long as their privacy practices are substantially
                similar to ours.
                <br />
                <br />
                Non-personally identifiable or aggregate information may be used
                by us for any purposes permitted by law and may be shared with
                any number of parties, provided that such information shall not
                specifically identify you.
                <br />
                <br />
                <span>Cookies and Similar Technologies</span>
                <br />
                <br />
                “Cookies” are pieces of information that may be placed on your computer by a service for the purpose of facilitating and enhancing your communication and interaction with that service. Many services use cookies for these purposes. We may use cookies (and similar items such as clear gifs, web beacons, tags, etc.) on our Service to customize your visit and for other purposes to make your visit more convenient or to enable us to enhance our Service. We may also use and place cookies (and similar items) on your computer from our third party service providers in connection with the Service, such as an analytics provider that helps us manage and analyze Service usage, as described more fully below. In addition, our advertisers and business partners may set cookies and similar items on your computer when you use our Service. You may stop or restrict the placement of cookies on your computer or flush them from your browser by adjusting your web browser preferences, in which case you may still use our Service, but it may interfere with some of its functionality. Cookies and similar items are not used by us to automatically retrieve personally identifiable information from your computer without your knowledge.
                <br />
                <br />
                If you delete your cookies, change browsers or use a different
                cookie, our cookie (or an opt-out cookie) may no longer work and
                you will have to re input (or opt-out) again.
                <br />
                <br />
                <span>Analytics and Conversion Tracking</span>
                <br />
                <br />
                We may use analytics services that use cookies, javascript and
                similar technologies to help us analyze how users use the
                Service. The information generated by these services about your
                use of the Service (including your IP address or a truncated
                version of your IP address) is transmitted to and stored by
                analytics service providers on their servers. Those service
                providers will use this information for the purpose of
                evaluating your, and other users’, use of the Service, compiling
                reports for us on website activity and providing other services
                relating to website activity and Internet usage.
                <br />
                <br />
                We may collect information about your computer, including your
                IP address, device information, operating system and browser
                type, for system administration and in order to create reports.
                This is statistical data about our users’ browsing actions and
                patterns, and does not identify any individual.
                <br />
                <br />
                For example, we use cookies on our site for Google Analytics
                (the “Analytics Service”) and various other analytics tools. The
                Analytics Service is a web-based analytics tool that helps
                website owners understand how visitors engage with their
                website. The Analytics Service customers can view a variety of
                reports about how visitors interact with their website so that
                they can improve it.
                <br />
                <br />
                Like many services, the Analytics Service uses first-party
                cookies to track visitor interactions as in our case, where they
                are used to collect information about how visitors use our site.
                We then use the information to compile reports and to help us
                improve our site.
                <br />
                <br />
                Cookies contain information that is transferred to your
                computer’s hard drive. These cookies are used to store
                information, such as the time that the current visit occurred,
                whether the visitor has been to the site before and what site
                referred the visitor to the web page.
                <br />
                <br />
                The Analytics Service collects information anonymously. They
                report website trends without identifying individual visitors.
                We may also use Google conversion tracking and/or similar
                services to help us understand your and other users’ use of the
                Service, and we are not liable to put here the links on how to
                opt out of those different services we use for our analytics
                needs.
                <br />
                <br />
                <span>Automatically Collected Information</span>
                <br />
                <br />
                When you access the Service or open one of our HTML emails, we may automatically record certain information, clicks/click throughs etc. from your system by using cookies and other types of tracking technologies. This "automatically collected" information may include Internet Protocol address ("IP Address"), a unique user ID, device type, device identifiers, browser types and language, referring and exit pages, platform type, version of software installed, system type, the content and pages that you access on the Service, the number of clicks, the amount of time spent on pages, the dates and times that you visit the Service, and other similar information. Depending on the law of your country of residence, your IP address may legally be considered personally identifiable information.
                <br />
                <br />
                <span>Security- How do we secure data handling for personal and sensitive user data?</span>
                <br />
                <br />
                The security of your personally identifiable information is very important to us. When we collect your personally identifiable information online, we use reasonable efforts to protect it from unauthorized access. However, due to the inherently open nature of the Internet, we cannot guarantee that your personally identifiable information will be completely free from unauthorized access by third parties such as hackers and your use of our Service demonstrates your assumption of this risk. We have put in place reasonable physical, electronic, and managerial procedures to safeguard the information we collect. Only those employees who need access to your information in order to perform their duties are authorized to have access to your personally identifiable information. For more information on protecting your privacy, please visit www.ftc.gov/privacy.
                <br />
                <br />
                <span>Your Disclosures In Blogs And Other Social Media</span>
                <br />
                <br />
                You should be aware that personally identifiable information which you voluntarily include and transmit online on the Service or in a publicly accessible blog, chat room, social media platform or otherwise online, or that you share in an open forum such as an in-person panel or survey, may be viewed and used by others without any restrictions. We are unable to control such uses of your personally identifiable information, and by using the Service or any other online services you assume the risk that the personally identifiable information provided by you may be viewed and used by third parties for any number of purposes. If you login to the Websites and/or Service through your Facebook account, connect to a third party service such as Facebook, Google or Twitter through an icon or link on the Service or otherwise link your Hushbunny.com account with a third party service, we may share the contents of your post and associated information (such as your user name, the fact that your connection originated from the Service, and other relevant usage and diagnostic information) with such third party. With your one-time consent, we may also send information about the content you watch and your activities on the Service to such third parties. Once such information is shared, the use of your information will be subject to that service's privacy policy and this Privacy Policy will not apply.
                <br />
                <br />
                <span>Protection for Children</span>
                <br />
                <br />
                We generally do not collect personally identifiable information
                from children under the age of 13. If at any time in the future
                we plan to collect personally identifiable information from
                children under 13, such collection and use, to the extent
                applicable, shall, when required, be done in compliance with the
                Children’s Online Privacy Protection Act (“COPPA”) and
                appropriate consent from the child’s parent or guardian will be
                sought where required by COPPA. When we become aware that
                personally identifiable information from a child under 13 has
                been collected without such a child's parent or guardian’s
                consent, we will use all reasonable efforts to delete such
                information from our database. However when a parent creates
                their profile and then add kids and start building their memory
                lanes, we consider that as consent provided by parents, and that
                entire workflow of collecting and using children information
                would be considered ‘By consent’ of parents and/or guardians.
                <br />
                <br />
                However the very nature of our products is building memory lanes
                of kids, our users remain parents or guardians of the kids, so
                even though we have kids moments, we are expecting kids’ parents
                or guardians to add those moments and build their memory lanes
                and conduct activity on Hushbunny.com as per our privacy policy
                and terms of service. If any personal identifiable information
                is for children under the age of 13, it is the parents or
                guardians who are putting that information and Hushbunny.com
                would not be considered even remotely liable under COPPA or
                other children’s privacy protection laws as users of
                Hushbunny.com are people above the age of 13 (parents or
                guardians of children above the age of 13), and
                parents/guardians would protect and indemnify parents of any and
                all liabilities, and liabilities of Hushbunny (products and
                promoters/heirs/families/ and every staff etc.) would be $0.
                Hushbunny is not liable for any hacking of data, data thefts,
                loss or stealing of any data to 3rd parties, hackers. We strive
                with our Tech partners that this doesn’t happen, but we are not
                liable for any loss or damages that happen due to data you
                posted on Hushbunny, all users of Hushbunny will indemnify
                Hushbunny, and we will have $0 liability for any of these.
                <br />
                <br />
                <span>Other Services</span>
                <br />
                <br />
                As a convenience to you, we may provide links to third-party
                Services from within our Service. We are not responsible for the
                privacy practices or content of these third-party sites. When
                you link away from our Service, you do so at your own risk.
                <br />
                <br />
                Changes to this Privacy Policy
                <br />
                We reserve the right, at our discretion, to change, modify, add,
                or remove portions from this Privacy Policy at any time.
                However, if at any time in the future we plan to use personally
                identifiable information in a way that materially differs from
                this Privacy Policy, including sharing such information with
                more third parties, we will post such changes here even if we
                don’t broadcast to all users over email. Your continued use of
                the Service and our services following the posting of any
                changes to this Privacy Policy means you accept such changes.
                <br />
                <br />
                <span>Opt-Out Process</span>
                <br />
                <br />
                All unsubscribe or opt-out requests may be made by clicking the
                “unsubscribe” link at the bottom of the relevant email. We will
                process your request within a reasonable time after receipt.
                However, we are not responsible for, and in some cases we are
                incapable of, removing your personally identifiable information
                from the lists of any third party who has previously been
                provided your information in accordance with this Privacy Policy
                or your consent. You should contact such third parties directly.
                If you would like to update or correct any personally
                identifiable information that you have provided to us, please
                email us at{" "}
                <a
                  href="mailto:contact@hushbunny.com"
                  style={{ textDecoration: "underline", color: "#0066d6" }}
                >
                  contact@hushbunny.com
                </a>{" "}
                and once we confirm your information, we will update such
                information within a reasonable amount of time. Also there will
                be some emails which won’t have an unsubscribe link as we
                consider such emails mandatory. Only emails which are not
                mandatory will have an unsubscribe button which will let you
                unsubscribe. If you still keep getting mails from Hushbunny,
                Hushbunny won’t be liable for these emails and you are free to
                click ‘move to junk’ and might not get those emails again.
                <br />
                <br />
                Communications with Hushbunny.com
                <br />
                By providing your contact information to us (including, without
                limitation, your email address, physical address, and phone
                number) (collectively the “Channels”) to us, you expressly
                consent to receive communications from us. We may use the
                Channels to communicate with you, to send information that you
                have requested or to send information about other products or
                services developed or provided by us or our business partners,
                provided that, we will not give your contact information to
                another party to promote their products or services directly to
                you without your consent or as set forth in this Privacy Policy.
                <br />
                <br />
                By using the Service, you expressly consent to receive
                in-product communications from us (including, without
                limitation, push notifications on the App).
                <br />
                <br />
                By providing your phone number to us, you expressly consent to
                receive phone calls and/or text messages from us. We will not
                give your phone number to another party to promote their
                products or services directly to you without your consent or as
                set forth in this Privacy Policy.
                <br />
                <br />
                Any phone calls and/or text messages delivered to your phone or
                device may cause you to incur extra data, text messaging, or
                other charges from your wireless carrier. MESSAGE AND DATA RATES
                MAY APPLY. You are solely responsible for any carrier charges
                incurred as a result of phone and/or text communications from
                Hushbunny.com.
                <br />
                <br />
                Any communication or material you transmit to us by email or
                otherwise, including any data, questions, comments, suggestions,
                or the like is, and will be treated as, non-confidential and
                nonproprietary. Except to the extent expressly covered by this
                Privacy Policy, anything you transmit or post may be used by us
                for any purpose, including but not limited to, reproduction,
                disclosure, transmission, publication, broadcast and posting.
                Furthermore, you expressly agree that we are free to use any
                ideas, concepts, know-how, or techniques contained in any
                communication you send to us, as well as any data developed
                using the content of such communication, without compensation
                and for any purpose whatsoever, including but not limited to,
                developing, manufacturing and marketing products and services
                using such information.
                <br />
                <br />
                <span>No Rights of Third Parties</span>
                <br />
                <br />
                This Privacy Policy does not create rights enforceable by third
                parties, nor does it require disclosure of any personal
                information relating to users of the Service.
                <br />
                <br />
                Site Terms of Use
                <br />
                Use of this Service is also governed by, and subject to, the
                Terms of Use located at{" "}
                <a
                  href="https://hushbunny.com/terms"
                  style={{ textDecoration: "underline", color: "#0066d6" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  hushbunny.com/terms
                </a>{" "}
                (the “Terms”). This Privacy Policy is incorporated into the
                Terms. Your use, or access, of the Service constitutes your
                agreement to be bound by these provisions. IF YOU DO NOT AGREE
                TO THE TERMS AND THIS PRIVACY POLICY YOU MAY NOT ACCESS OR
                OTHERWISE USE THE SERVICE.
                <br />
                <br />
                <span>Servers</span>
                <br />
                <br />
                Our servers are maintained in the United States. By using the
                Service, you freely and specifically give us your consent to
                export your personally identifiable information to the United
                States and to store and use it in the United States as specified
                in this Privacy Policy. You understand that data stored in the
                United States may be subject to lawful requests by the courts or
                law enforcement authorities in the United States.
                <br />
                <br />
                <span>Governing Law</span>
                <br />
                <br />
                This Privacy Policy and our legal obligations hereunder are
                subject to the laws of the State of Pennsylvania, Cumberland
                county regardless of your location. You hereby consent to the
                exclusive jurisdiction of and venue in the courts located in the
                State of Pennsylvania, Cumberland county in all disputes arising
                out of or relating to the Services.
                <br />
                <br />
                THESE PROVISIONS WOULD BE UPDATED IN THE FUTURE AS THEY EVOLVE.
                VISIT{" "}
                <a
                  href="https://hushbunny.com/privacy"
                  style={{ textDecoration: "underline", color: "#0066d6" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HUSHBUNNY.COM/PRIVACY
                </a>{" "}
                TO SEE LATEST TERMS AT ANY TIME, even if we don’t broadcast the
                changes to all the users over email.
                <br />
                <br />
                <span>Contact- To submit any queries or regarding all resolutions</span>
                <br />
                <br />
                For questions or concerns relating to privacy, we can be
                contacted at{" "}
                <a
                  href="mailto:contact@hushbunny.com"
                  style={{ textDecoration: "underline", color: "#0066d6" }}
                >
                  contact@hushbunny.com
                </a>
                <br />
                <br />
                <span>Effective Date</span>
                <br />
                <br />
                This Privacy Policy was last updated on 11/20/2023.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Gift />
      <Footer />
    </>
  );
};

export default LegalPrivacy;
