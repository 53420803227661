import React from "react";
import { NavLink } from "react-router-dom";
import "./legalPage.css";

//components
import Footer from "../../components/Footer/Footer";
import Gift from "../../components/Gift/Gift";
import Navbar from "../../components/Navbar/Navbar";

const LegalTerms = () => {
  return (
    <>
      <div className="legal_container">
        <Navbar legal={true} />
        <div className="legal_wrapper">
          <div className="legal_header_section">
            <div className="legal_header">
              <div className="legal_title">Legal</div>
              {/* <div className="legal_subtitle">Effective Day 1 May 2022</div> */}
            </div>
            <div className="legal_header_nav">
              <div className="legal_nav_item">
                <NavLink to="/terms">Terms of use</NavLink>
              </div>
              <div className="legal_nav_item">
                <NavLink to="/privacy">Privacy Policy</NavLink>
              </div>
            </div>
          </div>
          <div className="legal_body">
            <div className="legalBody_wrapper">
              <div className="legalBody_title">
                Welcome and thanks for using Hushbunny!
              </div>
              <div className="legalBody_area">
                By using the Hushbunny mobile application, website located at
                Hushbunny.com, and the related mobile website, and
                (collectively, the “Websites”), you agree to be bound by these
                Terms of Service (this “Terms of Service” or “Agreement”),
                whether or not you register as a member of Hushbunny.com
                ("Member"). If you wish to become a Member and/or make use of
                the service (the “Service”), please read this Agreement. If you
                object to anything in this Agreement or the Hushbunny.com
                Privacy Policy, do not use the Service.
                <br />
                <br />
                <span>Hushbunny.com </span> its corresponding mobile app across
                operating systems, mobile website, and all apps/sites related to
                Hushbnny.com constitute the Site Name (part of Hushbunny Inc.),
                for which this agreement and these Terms of Service are
                unequivocally applicable.
                <br />
                <br />
                This Agreement is subject to change by Hushbunny.com at any
                time, effective upon posting on the relevant website. Your
                continued use of the Websites and the Service following
                Hushbunny.com posting of revised terms of any section of the
                Agreement will constitute your express and binding acceptance of
                and consent to the revised Agreement.
                <br />
                <br />
                PLEASE READ THIS AGREEMENT CAREFULLY AS IT CONTAINS IMPORTANT
                INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND
                OBLIGATIONS, INCLUDING VARIOUS LIMITATIONS AND EXCLUSIONS, AND A
                DISPUTE RESOLUTION CLAUSE THAT GOVERNS HOW DISPUTES WILL BE
                RESOLVED.
                <br />
                <br />
                <span>Electronic Agreement.</span>
                <br />
                <br />
                This Agreement is an electronic contract that sets out the
                legally binding terms of your use of the Websites and the
                Service. This Agreement may be modified by Hushbunny.com from
                time to time, such modifications to be effective upon posting by
                Hushbunny.com on the Websites. By accessing and/or using the
                Websites or becoming a Member, you accept this Agreement and
                agree to the terms, conditions and notices contained or
                referenced herein.
                <br />
                <br />
                <span>1. Access and Retention.</span>
                <br />
                <br />
                In order to access and retain this electronic Agreement, you
                must have access to the Internet, either directly or through
                devices that access web-based content, and pay any service fees
                associated with such access. In addition, you must use all
                equipment necessary to make such a connection to the World Wide
                Web, including a computer and modem or other access device.
                Please print a copy of this document for your records. To retain
                an electronic copy of this Agreement, you may save it into any
                word processing program.
                <br />
                <br />
                <span>2. Commercial Use of Service.</span>
                <br />
                <br />
                If you are using the Service and/or accessing the Websites on
                behalf of a company, entity, or organization (collectively, a
                “Subscribing Entity”), you represent and warrant that:
                <br />
                <br />
                a. You are an authorized representative of the Subscribing
                Entity, and that you have the authority to bind the Subscribing
                Entity to this Terms of Service;
                <br />
                b. You have read and understand this Terms of Service; and
                <br />
                c. You agree to this Terms of Service on behalf of the
                Subscribing Entity.
                <br />
                <br />
                Illegal and/or unauthorized uses of the Websites include, but
                are not limited to, browsing or downloading illegal content,
                collecting usernames and/or email addresses of members by
                electronic or other means for the purpose of sending unsolicited
                email, unauthorized framing of or linking to the Websites,
                sharing or disclosing your username or password to any third
                party or permitting any third party to access your account,
                attempting to impersonate another user or person, use of the
                Websites in any fraudulent or misleading manner, any automated
                use of the system, such as scraping the Websites, automated
                scripts, spiders, robots, crawlers, data mining tools or the
                like, interfering with, disrupting, or creating an undue burden
                on the Websites or the networks or services connected to the
                Websites, and using the Websites in a manner inconsistent with
                any and all applicable laws and regulations. Illegal and/or
                unauthorized use of the Websites may be investigated, and
                appropriate legal action may be taken, including without
                limitation, civil, criminal, and injunctive redress. Use of the
                Websites and Service is with the permission of Hushbunny.com,
                which may be revoked at any time, for any reason, in
                Hushbunny.com’s sole discretion.
                <br />
                <br />
                <span>3. Account Security.</span>
                <br />
                <br />
                You are responsible for maintaining the confidentiality of the
                username and password that you designate during the registration
                process, and you are fully responsible for all activities that
                occur under your username and password. You agree to (a)
                immediately notify Hushbunny.com of any unauthorized use of your
                username or password or any other breach of security, and (b)
                ensure that you exit from your account at the end of each
                session. Hushbunny.com will not be liable for any loss or damage
                arising from your failure to comply with this provision. You
                should use particular caution when accessing your account from a
                public or shared computer so that others are not able to view or
                record your password or other personal information. If you share
                your computer with others, you may wish to consider disabling
                your auto-login feature if you have it linked to your
                Hushbunny.com account.
                <br />
                <br />
                <span>4. Your Use of the Websites</span>
                <br />
                <br />
                a. You may not browse or download illegal content.
                <br />
                <br />
                b. You must not copy or capture, or attempt to copy or capture,
                any content from the Websites (the “Content”) or any part of the
                Websites, unless given express permission by Hushbunny.com.
                <br />
                <br />
                c. You must not copy, republish, adapt, make available or
                otherwise communicate to the public, display, perform, transfer,
                share, distribute or otherwise use or exploit any Content on or
                from the Platform, except (i) where such Content is created by
                you (such content, “Your Content”), or (ii) as permitted under
                these Terms of Service, and within the parameters set by the
                person or entity that uploaded the Content (the “Uploader”) (for
                example, under the terms of Creative Commons licenses selected
                by the Uploader).
                <br />
                <br />
                d. You must not use any Content (other than Your Content) in any
                way that is designed to create a separate content service or
                that replicates any part of the Websites’ offering.
                <br />
                <br />
                e. You must not employ scraping or similar techniques to
                aggregate, repurpose, republish or otherwise make use of any
                Content.
                <br />
                <br />
                f. You must not employ the use of bots, botnets, scripts, apps,
                plugins, extensions or other automated means to register
                accounts, log-in, post comments, or otherwise to act on your
                behalf, particularly where such activity occurs in a multiple or
                repetitive fashion. You must not offer or promote the
                availability of any such techniques or services to any other
                users of the Websites.
                <br />
                <br />
                g. You must not alter or remove, or attempt to alter or remove,
                any trademark, copyright or other proprietary or legal notices
                contained in, or appearing on, the Websites or any Content
                appearing on the Websites (other than Your Content).
                <br />
                <br />
                h. You must not, and must not permit any third party to, copy or
                adapt the object code of the Websites, or reverse engineer,
                reverse assemble, decompile, modify or attempt to discover any
                source or object code of any part of the Websites, or circumvent
                or attempt to circumvent or copy any copy protection mechanism
                or access any rights management information pertaining to
                Content other than Your Content.
                <br />
                <br />
                i. You must not use the Websites to upload, post, store,
                transmit, display, copy, distribute, promote, make available or
                otherwise communicate to the public:
                <br />
                <br />
                ● any Content that is offensive, abusive, libelous, defamatory,
                obscene, racist, sexually explicit, ethnically or culturally
                offensive, indecent, that promotes violence, terrorism, or
                illegal acts, incites hatred on grounds of race, gender,
                religion or sexual orientation, or is otherwise objectionable in
                Hushbunny.com’s sole and reasonable discretion;
                <br />
                <br />
                ● any information, Content or other material that violates,
                plagiarizes, misappropriates or infringes the rights of third
                parties including, without limitation, copyright, trademark
                rights, rights of privacy or publicity, confidential information
                or any other right; or
                <br />
                <br />
                ● any Content that violates, breaches or is contrary to any law,
                rule, regulation, court order or is otherwise is illegal or
                unlawful in Hushbunny.com’s sole and reasonable opinion;
                <br />
                <br />
                ● any material of any kind that contains any virus, Trojan
                horse, spyware, adware, malware, bot, time bomb, worm, or other
                harmful or malicious component, which will or might overburden,
                impair or disrupt the Websites or servers or networks forming
                part of, or connected to, the Websites, or which does or might
                restrict or inhibit any other user's use and enjoyment of the
                Websites; or
                <br />
                <br />
                ● any unsolicited or unauthorized advertising, promotional
                messages, spam or any other form of solicitation.
                <br />
                <br />
                j. You must not commit or engage in, or encourage, induce,
                solicit or promote, any conduct that would constitute a criminal
                offense, give rise to civil liability or otherwise violate any
                law or regulation.
                <br />
                <br />
                k. You must not rent, sell or lease access to the Websites, or
                any Content on the Websites, although this will not prevent you
                from including links from Your Content to any legitimate online
                download store from where any item of Your Content may be
                purchased.
                <br />
                <br />
                l. You must not deliberately impersonate any person or entity or
                otherwise misrepresent your affiliation with a person or entity,
                for example, by registering an account in the name of another
                person or company, or sending messages or making comments using
                the name of another person.
                <br />
                <br />
                m. You must not stalk, exploit, threaten, abuse or otherwise
                harass another user, or any Hushbunny.com employee. If we feel
                that your behavior towards any of our employees is at any time
                threatening or offensive, we reserve the right to immediately
                terminate your membership and you will not be entitled to any
                refund of unused subscription fees.
                <br />
                <br />
                n. You must not sell or transfer, or offer to sell or transfer,
                any Hushbunny.com account to any third party without the prior
                written approval of Hushbunny.com.
                <br />
                <br />
                o. You must not collect or attempt to collect personal data, or
                any other kind of information about other users, including
                without limitation, through spidering or any form of scraping.
                <br />
                <br />
                p. You must not violate, circumvent or attempt to violate or
                circumvent any data security measures employed by Hushbunny.com
                or any Uploader; access or attempt to access data or materials
                which are not intended for your use; log into, or attempt to log
                into, a server or account which you are not authorized to
                access; attempt to scan or test the vulnerability of
                Hushbunny.com’s servers, system or network or attempt to breach
                Hushbunny.com’s data security or authentication procedures;
                attempt to interfere with the Websites or the Services by any
                means including, without limitation, hacking Hushbunny.com’s
                servers or systems, submitting a virus, overloading,
                mail-bombing or crashing. Without limitation to any other rights
                or remedies of Hushbunny.com under these Terms of Service,
                Hushbunny.com reserves the right to investigate any situation
                that appears to involve any of the above, and may report such
                matters to, and cooperate with, appropriate law enforcement
                authorities in prosecuting any users who have participated in
                any such violations.
                <br />
                <br />
                You agree to comply with the above conditions, and acknowledge
                and agree that Hushbunny.com has the right, in its sole
                discretion, to terminate your account or take such other action
                as we see fit if you breach any of the above conditions or any
                of the other terms of these Terms of Service. This may include
                taking court action and/or reporting offending users to the
                relevant authorities.
                <br />
                <br />
                <span>5. Representations and Warranties.</span>
                <br />
                <br />
                You hereby represent and warrant to Hushbunny.com as follows:
                <br />
                <br />
                a.Your Content, and each and every part thereof, is an original
                work by you, or you have obtained all rights, licenses, consents
                and permissions necessary in order to use, and (if and where
                relevant) to authorize Hushbunny.com to use, Your Content
                pursuant to these Terms of Service, including, without
                limitation, the right to upload, reproduce, store, transmit,
                distribute, share, publicly display, publicly perform, make
                available and otherwise communicate to the public Your Content,
                and each and every part thereof, on, through or via the
                Websites, any and all Services and any third party services.
                <br />
                <br />
                b.Your Content and the availability thereof on the Platform does
                not and will not infringe or violate the rights of any third
                party, including, without limitation, any intellectual property
                rights, performers' rights, rights of privacy or publicity, or
                rights in confidential information.
                <br />
                <br />
                c.You have obtained any and all necessary consents, permissions
                and/or releases from any and all persons appearing in Your
                Content in order to include their name, images, videos, voice,
                performance or likeness in Your Content and to publish the same
                on the Websites and via any third party services.
                <br />
                <br />
                d.Your Content, including any comments that you may post on the
                Websites, is not and will not be unlawful, offensive, abusive,
                libelous, defamatory, obscene, racist, sexually explicit,
                ethnically or culturally offensive, indecent, will not promote
                violence, terrorism, or illegal acts, or incite hatred on
                grounds of race, gender, religion or sexual orientation.
                <br />
                <br />
                e.Your Content does not and will not create any liability on the
                part of Hushbunny.com, its subsidiaries, affiliates, successors,
                and assigns, and their respective employees, agents, directors,
                officers and/or shareholders.
                <br />
                <br />
                Hushbunny.com reserves the right to remove Your Content, suspend
                or terminate your access to the Platform and/or pursue all legal
                remedies if we believe that any of Your Content breaches any of
                the foregoing representations or warranties, or otherwise
                infringes another person's rights or violates any law, rule or
                regulation.
                <br />
                <br />
                <span>6. Term.</span>
                <br />
                <br />
                This Agreement will remain in full force and effect while you
                use the Websites and/or Service. You may terminate your
                membership and/or subscription at any time online on the
                Hushbunny app. If you resign or cancel your membership and/or
                subscription to Hushbunny.com, to help Hushbunny.com analyze and
                improve the Service, you may be asked to provide a reason for
                your resignation/cancellation. Hushbunny.com may terminate your
                membership and/or subscription for any reason by sending notice
                to you at the email address you provide in your application for
                membership, or such other email address as you may later provide
                to Hushbunny.com. If Hushbunny.com terminates your
                account/membership in the Service because you have breached this
                Agreement, you will not be entitled to any refund of unused
                subscription fees, nor to any explanation. All decisions
                regarding the termination of accounts shall be made in the sole
                discretion of Hushbunny.com. Hushbunny.com is not required to
                provide you notice prior to terminating your account, membership
                and/or subscription. Hushbunny.com is not required, and may be
                prohibited, from disclosing a reason for the termination of your
                account. Even after your membership or subscription is
                terminated, this Agreement will remain in effect. All terms that
                by their nature may survive termination of this Agreement shall
                be deemed to survive such termination.
                <br />
                <br />
                <span>7. Modifications to Service.</span>
                <br />
                <br />
                Hushbunny.com reserves the right at any time to modify or
                discontinue, temporarily or permanently, the Service (or any
                part thereof) with or without notice. You agree that
                Hushbunny.com shall not be liable to you or to any third party
                for any modification, suspension or discontinuance of the
                Service.
                <br />
                <br />
                <span>8. Blocking of IP Addresses.</span>
                <br />
                In order to protect the integrity of the Services, Hushbunny.com
                reserves the right at any time in its sole discretion to block
                Members from certain IP addresses from accessing the Websites.
                <br />
                <br />
                <span>9. Content.</span>
                <br />
                <br />
                a. Proprietary Rights.
                <br />
                <br />
                Hushbunny.com retains all proprietary rights in the Websites and
                the Service. The Websites contains the copyrighted material,
                trademarks, and other proprietary information of Hushbunny.com,
                and its licensors. Except where we have given you express
                written permission, you may not copy, modify, publish, transmit,
                distribute, perform, display, or sell any such proprietary
                information. All content on Hushbunny.com is proprietary. Except
                where otherwise specified in this Agreement, all Content is
                copyrighted material of Hushbunny.com and for Hushbunny.com’s
                Members' use only. Distribution of Content to others is strictly
                prohibited. You agree that Hushbunny.com would be irreparably
                harmed by any violation or threatened violation of this section
                and that, therefore, Hushbunny.com shall be entitled to an
                injunction prohibiting you from any violation or threatened
                violation of this section, without posting bond, in addition to
                any other right or remedy it may have.
                <br />
                <br />
                We may provide links to third party websites, and some of the
                content appearing on Hushbunny.com may be supplied by third
                parties. Hushbunny.com has no responsibility for these third
                party websites nor for their content, which is subject to and
                governed by the Terms of Service and/or privacy policies, if
                any, of the applicable third party content providers.
                <br />
                <br />
                b. Ownership of Your Content; Licenses.
                <br />
                <br />
                You agree that any content you upload to the Websites and/or the
                Service (“Your Content”) shall become the property of
                Hushbunny.com. This shall have no effect on Sections 11
                (Copyright Policy), 14 (Limitation of Liability), and 15
                (Indemnity by You) of this Agreement.
                <br />
                <br />
                However, with respect to Your Content, Hushbunny.com grants you
                a worldwide, royalty-free and non-exclusive license(s) to use,
                distribute, reproduce, and publicly display such content, except
                with regard to commercial or for-profit use accounts.
                <br />
                <br />
                Any Content other than Your Content is the property of the
                relevant Uploader, and is or may be subject to copyright,
                trademark rights or other intellectual property or proprietary
                rights. Such Content may not be downloaded, reproduced,
                distributed, transmitted, re-uploaded, republished, displayed,
                sold, licensed, made available or otherwise communicated to the
                public or exploited for any purposes except via the features of
                the Websites from time to time and within the parameters set by
                the Uploader on the Service or with the express written consent
                of the Uploader. When you repost another user's Content, or
                include another user's Content in a set, you acquire no
                ownership rights whatsoever in that Content. Subject to the
                rights expressly granted in this section, all rights in Content
                are reserved to the relevant Uploader.
                <br />
                <br />
                <span>10. Restrictions on Use of Materials.</span>
                <br />
                <br />
                You acknowledge that Hushbunny.com contains images, text,
                videos, and other content (collectively, "Intellectual
                Property") that is protected by copyrights, patents, trademarks,
                trade secrets and/or other proprietary rights, and that these
                rights are valid and protected in all forms, media and
                technologies existing now or hereafter developed. All
                Intellectual Property is copyrighted under the United States
                copyright laws (and, if applicable, similar foreign laws), and
                Hushbunny.com owns a copyright in the selection, coordination,
                arrangement and enhancement of such Intellectual Property. All
                trademarks appearing on this Websites are trademarks of their
                respective owners. You may not modify, publish, transmit,
                distribute, perform, participate in the transfer or sale, create
                derivative works of, or in any way exploit, any of the
                Intellectual Property, in whole or in part. When Intellectual
                Property is downloaded to your computer, you do not obtain any
                ownership interest in such Intellectual Property. Modification
                of the Intellectual Property or use of the Intellectual Property
                for any other purpose, including, but not limited to, use of any
                Intellectual Property in printed form or on any other website or
                networked computer environment is strictly prohibited unless you
                receive our prior written consent.
                <br />
                <br />
                <span>11. Copyright Policy.</span>
                <br />
                <br />
                Hushbunny.com prohibits the submission or posting of any
                information that infringes or violates the copyright rights
                and/or other intellectual property rights (including rights of
                privacy and publicity) of any person or entity.
                <br />
                <br />
                Pursuant to Title 17, United States Code, Section 512(c)(2) or
                for any other claim of copyright infringement, you hereby agree
                that notifications of claimed copyright infringement be sent by
                your email to:
                <br />
                <br />
                <span>
                  <a
                    href="mailto:contact@hushbunny.com"
                    style={{ textDecoration: "underline", color: "#0066d6" }}
                  >
                    contact@hushbunny.com
                  </a>
                </span>
                <br />
                <br />
                If you believe that your intellectual property right (or such a
                right that you are responsible for enforcing) is infringed by
                any content on the Site, please write to Hushbunny.com at the
                address shown above, giving a written statement that contains:
                <br />
                <br />
                a. identification of the copyrighted work and/or intellectual
                property right claimed to have been infringed;
                <br />
                <br />
                b. identification of the allegedly infringing material on the
                Site that is requested to be removed;
                <br />
                <br />
                c. your name, address, and daytime telephone number, and an
                e-mail address if available;
                <br />
                <br />
                d. a statement that you have a good faith belief that the use of
                the copyrighted work and/or exercise of the intellectual
                property right is not authorized by the owner, its agent, or the
                law;
                <br />
                <br />
                e. a statement that the information in the notification is
                accurate, and, under penalty of perjury, that the signatory is
                authorized to act on behalf of the owner of the right that is
                allegedly infringed; and
                <br />
                <br />
                f. the signature of the intellectual property right owner or
                someone authorized on the owner's behalf to assert infringement
                of the right.
                <br />
                <br />
                Hushbunny.com will process any notice of alleged infringement
                which it receives and will take appropriate action as required
                by the Digital Millennium Copyright Act (DMCA) 17 U.S.C.
                512(c)(3) or other applicable copyright law. U.S. law provides
                significant penalties for submitting such a statement falsely.
                Under appropriate circumstances, persons who repeatedly submit
                infringing or unlawful material will be prohibited from posting
                further submissions.
                <br />
                <br />
                <span>12. Liability for Content.</span>
                <br />
                <br />
                You hereby acknowledge and agree that Hushbunny.com (i) stores
                Content and other information at the direction, request and with
                the authorization of its users, (ii) acts merely as a passive
                conduit and/or host for the uploading, storage and distribution
                of such Content, and (iii) plays no active role and gives no
                assistance in the presentation or use of the Content. You are
                solely responsible for all of Your Content that you upload, post
                or distribute to, on or through the Websites, and to the extent
                permissible by law, Hushbunny.com excludes all liability with
                respect to all Content (including Your Content) and the
                activities of its users with respect thereto.
                <br />
                <br />
                You hereby acknowledge and agree that Hushbunny.com cannot and
                does not review the Content created or uploaded by its users,
                and neither Hushbunny.com nor its subsidiaries, affiliates,
                successors, assigns, employees, agents, directors, officers and
                shareholders has any obligation, and does not undertake or
                assume any duty, to monitor the Websites for Content that is
                inappropriate, that does or might infringe any third party
                rights, or has otherwise been uploaded in breach of these Terms
                of Service or applicable law.
                <br />
                <br />
                Hushbunny.com and its subsidiaries, affiliates, successors,
                assigns, employees, agents, directors, officers and shareholders
                hereby exclude, to the fullest extent permitted by law, any and
                all liability which may arise from any Content uploaded to the
                Websites by users, including, but not limited to, any claims for
                infringement of intellectual property rights, rights of privacy
                or publicity rights, any claims relating to publication of
                defamatory, pornographic, obscene or offensive material, or any
                claims relating to the completeness, accuracy, currency or
                reliability of any information provided by users of the
                Websites. By using the Websites, you irrevocably waive the right
                to assert any claim with respect to any of the foregoing against
                Hushbunny.com or any of its subsidiaries, affiliates,
                successors, assigns, employees, agents, directors, officers or
                shareholders.
                <br />
                <br />
                We don’t charge you to use Hushbunny products and services, but
                we will use your content to display advertisements, make
                personalized recommendations to you, display advertisements to
                you using your profile information.
                <br />
                <br />
                Using our products and services, you also give us legal
                permission to use your content (even if it is exclusive and
                intellectual property content of yours) and grant us a
                non-exclusive, royalty-free, worldwide license, and also to use
                your content any which way (not just limited to modify, share,
                curate) with all rights as if it were our very own exclusive
                intellectual property. Even if you have deleted your content, we
                might still have them stored in our databases and servers.
                <br />
                <br />
                Hushbunny is also not liable for any hacking of data, data
                thefts, loss or stealing of any data to 3rd parties, hackers. We
                strive with our Tech partners that this doesn’t happen, but we
                are not liable for any loss or damages that happen due to data
                you posted on Hushbunny, all users of Hushbunny will indemnify
                Hushbunny, and we will have $0 liability for any of these.
                <br />
                <br />
                <span>13.Repeat Infringers.</span>
                <br />
                <br />
                Hushbunny.com will suspend or terminate your access to the
                Websites if Hushbunny.com determines, in its sole and reasonable
                discretion, that you have breached these Terms of Service.
                <br />
                <br />
                If we receive a valid notification from a third party in
                accordance with our reporting processes or applicable law that
                any of Your Content infringes the copyright or other rights of
                such third party, or if we believe that your behavior is
                inappropriate and violates our Terms of Service, your access to
                these Websites will be terminated forthwith by us at our sole
                discretion.
                <br />
                <br />
                We will also suspend or terminate your account without warning
                if ordered to do so by a court, and/or in other appropriate
                circumstances, as determined by Hushbunny.com at its sole
                discretion.
                <br />
                <br />
                Please note that we do not offer refunds to Members whose
                accounts are terminated as a result of repeated infringement of
                these Terms of Service.
                <br />
                <br />
                <span>14. Limitation of Liability.</span>
                <br />
                <br />
                In no event shall Hushbunny.com be liable for any damages
                whatsoever, whether direct, indirect, general, special,
                compensatory, consequential, and/or incidental, arising out of
                or relating to the Websites or Service, or use thereof. Nothing
                contained in this Websites or in any written or oral
                communications from Hushbunny.com or its employees or agents
                shall be construed to make any promise, covenant, or guaranty,
                all of which are explicitly disclaimed hereby, contrary to the
                statements and disclaimers contained in this paragraph.
                <br />
                <br />
                The content and functionality on the Websites and the services
                provided by employees of the Websites are offered "as is"
                without warranty of any kind, either express or implied,
                including, without limitation, implied warranties of
                merchantability, fitness for a particular purpose, title and
                non-infringement. Hushbunny.com makes no warranties, express or
                implied, as to the ownership, accuracy, completeness or adequacy
                of the Websites’ content or that the functionality of the
                Websites will be uninterrupted or error-free or free from virus
                or third party attack. You hereby acknowledge that your use of
                the Websites and the Service is at your sole risk. UNDER NO
                CIRCUMSTANCES SHALL Hushbunny.com, ITS OFFICERS, OWNERS,
                EMPLOYEES OR AGENTS AND THEIR RESPECTIVE HEIRS, SUCCESSORS AND
                ASSIGNS BE LIABLE FOR ANY DAMAGES, INCLUDING DIRECT, INCIDENTAL,
                PUNITIVE, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES THAT
                DIRECTLY OR INDIRECTLY RESULT FROM USE OF, OR INABILITY TO USE,
                THE WEBSITES OR SERVICE OR THE INFORMATION CONTAINED THEREIN,
                INCLUDING WITHOUT LIMITATION FOR VIRUSES ALLEGED TO HAVE BEEN
                OBTAINED FROM THE WEBSITES, EVEN IF Hushbunny.com HAS BEEN
                NOTIFIED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES AND
                REGARDLESS OF THE THEORY OF LIABILITY.
                <br />
                <br />
                TO THE GREATEST EXTENT PERMITTED BY LAW, YOU AGREE THAT IN NO
                EVENT WILL Hushbunny.com HAVE ANY LIABILITY, CONTINGENT OR
                OTHERWISE, FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL,
                OR EXEMPLARY DAMAGES IN ANY WAY ARISING OUT OF OR RELATING TO
                THE AGREEMENT, WEBSITES OR THE SERVICE, INCLUDING, BUT NOT
                LIMITED TO LOST PROFITS; LOST DATA; LOSS OF GOODWILL; COPYRIGHT,
                TRADEMARK, OR OTHER INTELLECTUAL PROPERTY INFRINGEMENT; WORK
                STOPPAGE; EQUIPMENT FAILURE OR MALFUNCTION; ILLEGAL, IMMORAL OR
                FRAUDULENT ACTIVITY; PERSONAL INJURY; PROPERTY DAMAGE; OR ANY
                OTHER DAMAGES OR LOSSES, EVEN IF Hushbunny.com HAS BEEN ADVISED
                OF THE POSSIBILITY THEREOF, AND REGARDLESS OF THE LEGAL OR
                EQUITABLE THEORY (CONTRACT, TORT, STATUTE, INDEMNITY OR
                OTHERWISE) UPON WHICH SUCH LIABILITY IS BASED.
                <br />
                <br />
                Even if in the event that any of the foregoing limitations are
                deemed to be unenforceable, the liability of Hushbunny (site,
                app, ITS OFFICERS, OWNERS, EMPLOYEES OR AGENTS AND THEIR
                RESPECTIVE HEIRS, SUCCESSORS AND ASSIGNS) will remain a total of
                $0 in any case whatsoever. You agree to this unequivocally. We
                are not charging our users/customers any amount to use our app,
                rely on other digital advertising and other avenues to monetize
                the app, therefore our liability to our app and product users
                would also remain $0 perpetually (no liability to be paid to any
                users of Hushbunny products)
                <br />
                <br />
                <span>15. Indemnity by You.</span>
                <br />
                <br />
                You agree to indemnify and hold Hushbunny.com, its subsidiaries,
                affiliates, officers, agents, and other partners and employees,
                harmless from any loss, liability, claim, or demand, including
                reasonable attorneys' fees, arising out of or related to:
                <br />
                <br />
                a. your use of the Service and/or Websites in violation of this
                Agreement and/or arising from a breach of this Agreement
                including without limitation your representations and warranties
                set forth above;
                <br />
                <br />
                b. any third party claim of infringement of copyright or other
                intellectual property rights or invasion of privacy arising from
                the hosting of Your Content on the Websites, and/or your making
                available thereof to other users of the Websites, and/or the
                actual use of Your Content by other users of the Websites or
                related services in accordance with these Terms of Service and
                the parameters set by you with respect to the distribution and
                sharing of Your Content;
                <br />
                <br />
                c. any activity related to your account, either by you or by any
                other person accessing your account with or without your consent
                unless such activity was caused by the act of Hushbunny.com.
                <br />
                <br />
                <span>16. Attorney Fees.</span>
                <br />
                <br />
                In the event that Hushbunny.com is successful in whole or in
                part in any action or proceeding related to or arising from this
                Agreement, you shall be responsible for Hushbunny.com’s
                attorneys' fees and costs.
                <br />
                <br />
                <span>17. Parental or Guardian Permission.</span>
                <br />
                <br />
                Some of the Content on the Websites may not be appropriate for
                children. CHILDREN UNDER THE AGE OF 17 ARE NOT PERMITTED TO USE
                THE WEBSITES UNLESS A SUPERVISING PARENT OR GUARDIAN IS PRESENT.
                <br />
                <br />
                <span>18. Privacy.</span>
                <br />
                <br />
                Use of the Websites and/or the Service is also governed by our
                Privacy Policy, located at{" "}
                <a
                  href="https://hushbunny.com/privacy"
                  style={{ textDecoration: "underline", color: "#0066d6" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  hushbunny.com/privacy
                </a>
                <br />
                <br />
                <span>
                  19. Jurisdiction and Choice of Law; Dispute Resolution.
                </span>
                <br />
                <br />
                If there is any dispute arising out of the Websites and/or the
                Service, by using the Websites and/or Service, you expressly
                agree that any such dispute shall be governed by the laws of the
                State of Pennsylvania, Cumberland county, without regard to its
                conflict of law provisions, and you expressly agree and consent
                to the exclusive jurisdiction and venue of the state and federal
                courts of the State of Pennsylvania, Cumberland county, for the
                resolution of any such dispute. Acceptance of the terms and
                conditions of this Agreement constitutes your consent to be sued
                in such courts and to accept service of process outside the
                State of Pennsylvania, Cumberland county with the same force and
                effect as if such service had been made within the State of
                Pennsylvania, Cumberland county. You hereby agree to accept
                service of process for any action hereunder by certified mail
                return receipt requested which service shall have the same force
                and effect as though service had been effected by personal
                service in the applicable jurisdiction. If any part of these
                terms is unlawful, void, or unenforceable, that part will be
                deemed severable and will not affect the validity and
                enforceability of the remaining provisions.
                <br />
                <br />
                <span>20. Arbitration Provision/No Class Action.</span>
                <br />
                <br />
                Except where prohibited by law, as a condition of using the
                Websites and/or Service, you agree that any and all disputes,
                claims and causes of action (collectively, "Claim") arising out
                of or connected with the Websites and/or Service, shall be
                resolved individually, without resort to any form of class
                action, exclusively by binding arbitration under the rules of
                the American Arbitration Association for full and final
                settlement of such Claim, and judgment on the award rendered in
                the arbitration may be entered in any court having jurisdiction
                thereof. Such arbitration shall be held in accordance with the
                Rules for Expedited Procedures under the Commercial Arbitration
                Rules of the American Arbitration Association or other mutually
                agreeable organization, before a single arbitrator (with
                knowledge and expertise of copyright law if the claim is all or
                partially for copyright infringement), selected by agreement of
                both parties or by an independent mediator (with knowledge and
                expertise of copyright law if the claim is all or partially for
                copyright infringement) if the parties are unable to agree. The
                parties shall split the arbitration and/or mediator costs. An
                award rendered by the arbitrator(s) may be entered and confirmed
                by the courts of the State of Pennsylvania, Cumberland county.
                The parties agree that any post-arbitration action seeking to
                enforce an arbitration award or action seeking equitable or
                injunctive relief shall be brought exclusively in the courts of
                the State of Pennsylvania, Cumberland county.
                <br />
                <br />
                <span>21. No Third Party Beneficiaries.</span>
                <br />
                <br />
                You agree that, except as otherwise expressly provided in this
                Agreement, there shall be no third party beneficiaries to this
                Agreement.
                <br />
                <br />
                <span>22. Availability Outside the U.S.</span>
                <br />
                <br />
                If you access Hushbunny.com from outside of the United States,
                you do so at your own risk and are responsible for compliance
                with the laws of your jurisdiction. If you access the Websites
                from outside of the United States, you acknowledge that any
                personal information you provide will be processed in the United
                States and other geographies as selected by us in our sole
                discretion, and you hereby consent to the collection and
                processing of your personal information in a manner consistent
                with this Agreement and the Privacy Policy.
                <br />
                <br />
                <span>23. Entire Agreement.</span>
                <br />
                <br />
                This Agreement contains the entire agreement between you and
                Hushbunny.com regarding the use of the Websites and/or the
                Service.
                <br />
                <br />
                <span>24. Severability; Waiver.</span>
                <br />
                <br />
                If any provision of this Agreement is found to be invalid by any
                court having competent jurisdiction, the invalidity of such
                provision shall not affect the validity of the remaining
                provisions of this Agreement, which shall remain in full force
                and effect. No waiver of any term of this Agreement shall be
                deemed a further or continuing waiver of such term or any other
                term. In addition, Hushbunny.com’s failure to enforce any term
                of this Agreement shall not be deemed as a waiver of such term
                or otherwise affect Hushbunny.com’s ability to enforce such term
                at any point in the future.
                <br />
                <br />
                <span>25. Headings.</span>
                <br />
                <br />
                The section headings contained in this Agreement are for
                reference purposes only and shall not in any way affect the
                meaning or interpretation of this Agreement. Please contact us
                with any questions regarding this agreement.
                <br />
                <br />
                THESE PROVISIONS WOULD BE UPDATED IN THE FUTURE AS THEY EVOLVE.
                VISIT{" "}
                <a
                  href="https://hushbunny.com/terms"
                  style={{ textDecoration: "underline", color: "#0066d6" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HUSHBUNNY.COM/TERMS
                </a>{" "}
                TO SEE LATEST TERMS AT ANY TIME, even if we don’t broadcast the
                changes to all the users over email.
                <br />
                <br />
                I HAVE READ THIS AGREEMENT AND AGREE TO ALL OF THE PROVISIONS
                CONTAINED ABOVE.
                <br />
                <br />
                <span>Effective Date:</span>
                <br />
                <br />
                These Terms of Service were last updated on 07/17/2022.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Gift />
      <Footer />
    </>
  );
};

export default LegalTerms;
