import React from "react";

//images
import about_us_webp from "../../assets/Images/about_us.webp";
import about_us_svg from "../../assets/Images/about_us.svg";
import about_us_png from "../../assets/Images/about_us.png";
import line_webp from "../../assets/Images/Line.webp";
import line_svg from "../../assets/Images/Line.svg";
import line_png from "../../assets/Images/Line.png";

import aboutUsIcon1_webp from "../../assets/Images/ic_aboutUs_icon1.webp";
import aboutUsIcon1_svg from "../../assets/Images/ic_aboutUs_icon1.svg";
import aboutUsIcon1_png from "../../assets/Images/ic_aboutUs_icon1.png";

import aboutUsIcon2_webp from "../../assets/Images/ic_aboutUs_icon2.webp";
import aboutUsIcon2_svg from "../../assets/Images/ic_aboutUs_icon2.svg";
import aboutUsIcon2_png from "../../assets/Images/ic_aboutUs_icon2.png";

import aboutUsIcon3_webp from "../../assets/Images/ic_aboutUs_icon3.webp";
import aboutUsIcon3_svg from "../../assets/Images/ic_aboutUs_icon3.svg";
import aboutUsIcon3_png from "../../assets/Images/ic_aboutUs_icon3.png";

import aboutUsIcon4_webp from "../../assets/Images/ic_aboutUs_icon4.webp";
import aboutUsIcon4_svg from "../../assets/Images/ic_aboutUs_icon4.svg";
import aboutUsIcon4_png from "../../assets/Images/ic_aboutUs_icon4.png";

import aboutUsIcon5_webp from "../../assets/Images/ic_aboutUs_icon5.webp";
import aboutUsIcon5_svg from "../../assets/Images/ic_aboutUs_icon5.svg";
import aboutUsIcon5_png from "../../assets/Images/ic_aboutUs_icon5.png";

const AboutUs = () => {
  return (
    <>
      <div className="aboutUs_container" id="aboutUs">
        <div className="line_img">
          <picture>
            <source type="image/webp" srcSet={line_webp} />
            <source type="image/svg+xml" srcSet={line_svg} />
            <source type="image/png" srcSet={line_png} />
            <img src={line_png} alt="about-line" />
          </picture>
        </div>

        <div className="aboutUs_wrapper">
          <div className="aboutUs_banner">
            <div className="aboutUs_banner_img">
              <picture>
                <source type="image/webp" srcSet={about_us_webp} />
                <source type="image/svg+xml" srcSet={about_us_svg} />
                <source type="image/png" srcSet={about_us_png} />
                <img src={about_us_png} alt="aboutUs-banner" />
              </picture>
            </div>
          </div>
          <div className="aboutUs_info">
            <div className="aboutUs_info_header">
              <div className="aboutUs_title">About Us</div>
              <div className="aboutUs_subtitle">
                Hushbunny is the family storyteller we all so badly missed, to
                build memory lanes of your kids today, to be inherited and
                cherished by them when they grow up
              </div>
            </div>

            <div className="aboutUs_info_body">
              <div className="aboutUs_info_body_item">
                <span className="aboutUs_info_body_icon">
                  <picture>
                    <source type="image/webp" srcSet={aboutUsIcon1_webp} />
                    <source type="image/svg+xml" srcSet={aboutUsIcon1_svg} />
                    <source type="image/png" srcSet={aboutUsIcon1_png} />
                    <img src={aboutUsIcon1_png} alt="about-icon1" />
                  </picture>
                </span>
                <span className="aboutUs_info_body_val">
                  Now no more watching your kids grow just once.
                </span>
              </div>

              <div className="aboutUs_info_body_item">
                <span className="aboutUs_info_body_icon">
                  <picture>
                    <source type="image/webp" srcSet={aboutUsIcon2_webp} />
                    <source type="image/svg+xml" srcSet={aboutUsIcon2_svg} />
                    <source type="image/png" srcSet={aboutUsIcon2_png} />
                    <img src={aboutUsIcon2_png} alt="about-icon2" />
                  </picture>
                </span>
                <span className="aboutUs_info_body_val">
                  No more keeping those moments, memories, and events of your
                  kids just in your memory which your kids can never visit.
                </span>
              </div>

              <div className="aboutUs_info_body_item">
                <span className="aboutUs_info_body_icon">
                  <picture>
                    <source type="image/webp" srcSet={aboutUsIcon3_webp} />
                    <source type="image/svg+xml" srcSet={aboutUsIcon3_svg} />
                    <source type="image/png" srcSet={aboutUsIcon3_png} />
                    <img src={aboutUsIcon3_png} alt="about-icon3" />
                  </picture>
                </span>
                <span className="aboutUs_info_body_val">
                  No more leaving moments and memories of your kids in devices,
                  drives, and cloud storages never to visit them again.
                </span>
              </div>

              <div className="aboutUs_info_body_item">
                <span className="aboutUs_info_body_icon">
                  <picture>
                    <source type="image/webp" srcSet={aboutUsIcon4_webp} />
                    <source type="image/svg+xml" srcSet={aboutUsIcon4_svg} />
                    <source type="image/png" srcSet={aboutUsIcon4_png} />
                    <img src={aboutUsIcon4_png} alt="about-icon4" />
                  </picture>
                </span>
                <span className="aboutUs_info_body_val">
                  Why not build something which you, your kids, and future
                  generations can inherit to live and relive over and over
                  again?
                </span>
              </div>

              <div className="aboutUs_info_body_item">
                <span className="aboutUs_info_body_icon">
                  <picture>
                    <source type="image/webp" srcSet={aboutUsIcon5_webp} />
                    <source type="image/svg+xml" srcSet={aboutUsIcon5_svg} />
                    <source type="image/png" srcSet={aboutUsIcon5_png} />
                    <img src={aboutUsIcon5_png} alt="about-icon5" />
                  </picture>
                </span>
                <span className="aboutUs_info_body_val">
                  From the first time you heard 'Congrats, you are expecting',
                  or 'Congrats, you are going to be Dad', start adding moments
                  of your kids from their Day-0 (from even before they were
                  born!)
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
