import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Modal from '../../components/Modal/Modal';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import OtpInput from '../../components/OtpModal/OtpModel';
import axios from 'axios';
import Loader from '../../components/Loader/Loader';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ActionPage = () => {
    const [showModal, setShowModal] = useState(false);
    const [showDeactivateModal, setDeactivateShowModal] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [usersLoader, setUsersLoader] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const onConfirmationDelete = () => {
        setShowModal(false);
        setUsersLoader(true)
        const BaseUrl = "https://api-prod.hushbunny.com/user/v1/verify/sendOtp"
        axios
            .post(BaseUrl, {}, {
                headers: {
                    "accept-language": "en",
                },
            })
            .then((res) => {
                setModalOpen(true)
                setUsersLoader(false)

            })
            .catch((err) => {
                console.log("Sign-in Error :", err);
                toast.warn(err.response.data.message);
            });
    }
    //selector
    const userAuth = useSelector((state) => state.userAuthReducer.isAuth);

    //check if user is authorized
    if (!userAuth || userAuth !== "authorized") {
        return <Navigate to="/" />;
    }

    return (
        <>
            {/* Show loader while loading data */}
            {usersLoader && <Loader loaderName="Please wait" />}

            <Navbar legal={true} />

            <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={3}
                sx={{ height: '100vh', textAlign: 'center' }}
            >
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                        Action Page
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Paper sx={{ padding: 3, paddingTop: 0 }}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="error"
                            size="large"
                            onClick={handleOpenModal}
                            sx={{ margin: 2 }}
                        >
                            Delete
                        </Button>
                    </Paper>
                </Grid>
                {/* <Grid item xs={12} md={6} lg={4}>
                    <Paper sx={{ padding: 3 }}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="warning"
                            size="large"
                            onClick={() => setDeactivateShowModal(true)}
                            sx={{ margin: 2 }}
                        >
                            Deactivate
                        </Button>
                    </Paper>
                </Grid> */}

            </Grid>
            <Footer />
            {showModal && (
                <Modal
                    setShowModal={setShowModal}
                    memberType="user"
                    // memberID={userID}
                    modalTitle="Are you sure you want to delete your account?"
                    firstBtn="Delete"
                    secondBtn="Cancel"
                    onConfirmationDelete={onConfirmationDelete}
                />
            )}
            {modalOpen && (
                <OtpInput setShowModal={setModalOpen} setUsersLoader={setUsersLoader}
                    memberType="user"
                    // memberID={userID}
                    modalTitle="Please enter the OTP sent to your email."
                    firstBtn="Submit"
                    secondBtn="Cancel" />
            )}
            {showDeactivateModal && (
                <Modal
                    setShowModal={setDeactivateShowModal}
                    memberType="user"
                    // memberID={userID}
                    modalTitle="Are you sure you want to deactivate your account?"
                    firstBtn="Deactivate"
                    secondBtn="Cancel"
                />
            )}
            <ToastContainer />
        </>
    );
};

export default ActionPage;
