import React from "react";

//images
import featureImg1_webp from "../../assets/Images/ic_app_features1.webp";
import featureImg1_svg from "../../assets/Images/ic_app_features1.svg";
import featureImg1_png from "../../assets/Images/ic_app_features1.png";

import featureImg2_webp from "../../assets/Images/ic_app_features2.webp";
import featureImg2_svg from "../../assets/Images/ic_app_features2.svg";
import featureImg2_png from "../../assets/Images/ic_app_features2.png";

import featureImg3_webp from "../../assets/Images/ic_app_features3.webp";
import featureImg3_svg from "../../assets/Images/ic_app_features3.svg";
import featureImg3_png from "../../assets/Images/ic_app_features3.png";

import featureImg4_webp from "../../assets/Images/ic_app_features4.webp";
import featureImg4_svg from "../../assets/Images/ic_app_features4.svg";
import featureImg4_png from "../../assets/Images/ic_app_features4.png";

const Features = () => {
  return (
    <>
      <div className="features_container" id="features">
        <div className="features_wrapper">
          <div className="features_top">
            <div className="features_top_header">FEATURES</div>
            <div className="features_title">Awesome App Features</div>
            <div className="features_subtitle">
              Now don't miss any moments however small, and inherit them to your
              kids when they grow up.
            </div>
          </div>

          <div className="features_bottom">
            <div className="features_bottom_card">
              <div className="bottom_card_img">
                <picture>
                  <source type="image/webp" srcSet={featureImg1_webp} />
                  <source type="image/svg+xml" srcSet={featureImg1_svg} />
                  <source type="image/png" srcSet={featureImg1_png} />
                  <img src={featureImg1_png} alt="features-img1" />
                </picture>
              </div>
              <div className="bottom_card_title">
                Add moments and build memory lane for your kids
              </div>
              <div className="bottom_card_val">
                <ol type="1">
                  <li>Add your kid </li>
                  <li>Add the other parent</li>
                  <li>
                    Now both can start adding moments and build memory lane for
                    the kids
                  </li>
                </ol>
              </div>
            </div>

            <div className="features_bottom_card">
              <div className="bottom_card_img">
                <picture>
                  <source type="image/webp" srcSet={featureImg2_webp} />
                  <source type="image/svg+xml" srcSet={featureImg2_svg} />
                  <source type="image/png" srcSet={featureImg2_png} />
                  <img src={featureImg2_png} alt="features-img2" />
                </picture>
              </div>
              <div className="bottom_card_title">
                Select multiple kids and add a moment
              </div>
              <div className="bottom_card_val">
                <ol type="1">
                  <li>
                    Add a moment on memory lanes of more than 1 kids
                    simultaneously
                  </li>
                  <li>
                    Select the kids, select the moment date, and Hushbunny will
                    build memory lanes of selected kids with this moment
                  </li>
                </ol>
              </div>
            </div>

            <div className="features_bottom_card">
              <div className="bottom_card_img">
                <picture>
                  <source type="image/webp" srcSet={featureImg3_webp} />
                  <source type="image/svg+xml" srcSet={featureImg3_svg} />
                  <source type="image/png" srcSet={featureImg3_png} />
                  <img src={featureImg3_png} alt="features-img3" />
                </picture>
              </div>
              <div className="bottom_card_title">
                Build chronological memory lane for your kids
              </div>
              <div className="bottom_card_val">
                <ol type="1">
                  <li>
                    Choose a moment date and add moments on the date those
                    happened
                  </li>
                  <li>Post moments on a past date</li>
                  <li>Build chronological memory lanes of your kids</li>
                </ol>
              </div>
            </div>

            <div className="features_bottom_card">
              <div className="bottom_card_img">
                <picture>
                  <source type="image/webp" srcSet={featureImg4_webp} />
                  <source type="image/svg+xml" srcSet={featureImg4_svg} />
                  <source type="image/png" srcSet={featureImg4_png} />
                  <img src={featureImg4_png} alt="features-img4" />
                </picture>
              </div>
              <div className="bottom_card_title">
                Share moments with friends and family
              </div>
              <div className="bottom_card_val">
                <ol type="1">
                  <li>
                    Select any moment, and share the moment with friends and
                    family
                  </li>
                  <li>
                    Encourage them to join hushbunny to build memory lanes of
                    their kids
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
