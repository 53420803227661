import React from "react";
import "./loader.css";

const Loader = ({ loaderName }) => {
  return (
    <>
      <div className="loader_container">
        <div className="loader_main">
          <div className="loader"></div>
          <div className="loader_content">{loaderName}</div>
        </div>
      </div>
    </>
  );
};

export default Loader;
