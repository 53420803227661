import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import axios from "axios";
//pages
import LandingPage from "./pages/LandingPage/LandingPage";
import LegalPrivacy from "./pages/LegalPage/LegalPrivacy";
import LegalTerms from "./pages/LegalPage/LegalTerms";
import Unsubscribe from "./pages/Unsubscribe/Unsubscribe";
import SignIn from "./pages/SignIn/SignIn";
import ActionPage from "./pages/Action/Action";

const App = () => {

  const isAuth = window.localStorage.getItem("userAuth");

  if (isAuth && isAuth === "authorized") {
    const authToken = window.localStorage.getItem("authToken");
    axios.defaults.headers.common["authorization"] = "bearer " + authToken;
    axios.defaults.headers.common["accept-language"] = "en";
  }
  return (
    <>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route path="/terms" element={<LegalTerms />} />
        <Route path="/privacy" element={<LegalPrivacy />} />
        <Route exact path="/unsubscribe" element={<Unsubscribe />} />
        <Route exact path="/deleteaccount" element={<SignIn />} />
        <Route exact path="/action" element={<ActionPage />} />
      </Routes>
    </>
  );
};

export default App;
