import React, { useState } from "react";
import axios from "axios";
import "./signIn.css";

//redux
import { useDispatch } from "react-redux";
import { setUserAuth } from "../../redux/actions/userAuthActions";

//icons & images
import signInLogo from "./../../assets/Images/signIn/horizontal_full_logo.png";
import signInImg from "./../../assets/Images/signIn/signIn-img.png";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignIn = () => {
  //dispatcher
  const dispatch = useDispatch();

  //States to store email & password
  const [signinEmail, setSigninEmail] = useState("");
  const [signinpassword, setSigninPassword] = useState("");

  //State for show password icon
  const [showPassword, setShowPassword] = useState(false);

  //Sign-In method
  const SignInSubmit = (e) => {
    e.preventDefault();

    const signInData = { email: signinEmail, password: signinpassword, loginBy: "EMAIL", deviceType: "WEB" };
    const LoginURL = "https://api-prod.hushbunny.com/session/v1/login"
    axios
      .post(LoginURL, signInData, {
        headers: {
          "accept-language": "en",
        },
      })
      .then((res) => {
        dispatch(
          setUserAuth(
            window.localStorage.setItem("userAuth", "authorized"),
            window.localStorage.setItem("authToken", res.data.data.token),
            window.localStorage.setItem("userType", res.data.data.userType),
            window.localStorage.setItem(
              "signInData",
              JSON.stringify(res.data.data)
            )
          )
        );

        axios.defaults.headers.common["authorization"] =
          "bearer " + res.data.data.token;
        axios.defaults.headers.common["accept-language"] = "en";

        window.location.href = "/action";
      })
      .catch((err) => {
        console.log("Sign-in Error :", err);
        toast.warn(err.response.data.message);
      });
  };

  return (
    <>
      <div className="signIn_container">
        <div className="signIn_wrapper">
          <div className="signIn_left">
            <div className="signIn_leftLogo">
              <img src={signInLogo} alt="signIn-logo" />
            </div>
            <div className="signIn_leftHeader">
              <div className="signIn_main_header">Welcome back!</div>
              <div className="signIn_main_subheader">
                Before proceeding with the account deletion, please provide your details to ensure a secure process.
              </div>
            </div>
            <div className="signIn_leftForm">
              <form onSubmit={SignInSubmit}>
                <div className="login_form_inputArea">
                  <label htmlFor="">Email address</label>
                  <input
                    name="email"
                    type="email"
                    placeholder="Enter email address"
                    value={signinEmail}
                    required
                    onChange={(e) => setSigninEmail(e.target.value)}
                  />
                </div>
                <div className="login_form_inputArea">
                  <label htmlFor="">Password</label>
                  <input
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password"
                    value={signinpassword}
                    required
                    onChange={(e) => setSigninPassword(e.target.value)}
                  />
                  <div
                    className={`form_icon ${showPassword ? "showPass" : ""}`}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <VisibilityOutlined />
                    ) : (
                      <VisibilityOffOutlined />
                    )}
                  </div>
                </div>
                {/* <div className="form_infoArea">
                  <div className="remember">
                    <input type="checkbox" />
                    Remember me
                  </div>
                  <div className="forgot">
                    <NavLink to="/forgotPassword">Forgot Password?</NavLink>
                  </div>
                </div> */}
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
          <div className="signIn_right">
            <div className="signIn_rightImg">
              <img src={signInImg} alt="signIn-img" />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SignIn;
