import React from "react";

//images
import app_store_webp from "../../assets/Images/app_store_button.webp";
import app_store_svg from "../../assets/Images/app_store_button.svg";
import app_store_png from "../../assets/Images/app_store_button.png";
import play_store_webp from "../../assets/Images/play_store_button.webp";
import play_store_svg from "../../assets/Images/play_store_button.svg";
import play_store_png from "../../assets/Images/play_store_button.png";

const Gift = () => {
  return (
    <>
      <div className="gift_container">
        <div className="gift_wrapper">
          <div className="gift_title">
            Give them their best <span>gift</span>
          </div>
          <div className="gift_subtitle">
            Their whole life back to them in this memory lane of theirs,
            chronologically
          </div>
          <div className="download_btns gift">
            <a
              href="https://apps.apple.com/app/id1633444164"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="app_store">
                <div className="download_icon">
                  <picture>
                    <source type="image/webp" srcSet={app_store_webp} />
                    <source type="image/svg+xml" srcSet={app_store_svg} />
                    <source type="image/png" srcSet={app_store_png} />
                    <img src={app_store_png} alt="app-store-logo" />
                  </picture>
                </div>
              </button>
            </a>
            {/* <a
              href="https://play.google.com/store/apps/details?id=com.hushbunny.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="app_store">
                <div className="download_icon">
                  <picture>
                    <source type="image/webp" srcSet={play_store_webp} />
                    <source type="image/svg+xml" srcSet={play_store_svg} />
                    <source type="image/png" srcSet={play_store_png} />
                    <img src={play_store_png} alt="play-store-logo" />
                  </picture>
                </div>
              </button>
            </a> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Gift;
