import React from "react";
import "./unsubscribe.css";

//components
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

//images
import unsubscribe_img from "../../assets/Images/unsubscribe-img.png";

const Unsubscribe = () => {
  return (
    <>
      <div className="unsubscribe_container">
        <Navbar legal={true} />
        <div className="unsubscribe_wrapper">
          <div className="unsubscribe_body">
            <div className="unsubscribe_title">You've been unsubscribed.</div>
            <div className="unsubscribe_main_img">
              <picture>
                <source type="image/png" srcSet={unsubscribe_img} />
                <img src={unsubscribe_img} alt="unsubscribe-img" />
              </picture>
            </div>
            <div className="unsubscribe_subtitle">
              You can also manage all your email notifications from the Settings
              tab in your Hushbunny app.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Unsubscribe;
