import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";

//images & icons
import navLogo_svg from "../../assets/Images/hushbunny_logo.svg";
import navLogo_png from "../../assets/Images/hushbunny_logo.png";
import navCode_webpg from "../../assets/Images/qr.webp";
import navCode_svg from "../../assets/Images/qr.svg";
import navCode_png from "../../assets/Images/qr.png";

import navCodeLarge_webpg from "../../assets/Images/big_qr.webp";
import navCodeLarge_svg from "../../assets/Images/big_qr.svg";
import navCodeLarge_png from "../../assets/Images/big_qr.png";

// import HeroBunny_webp from "../../assets/Images/hero_bunny.webp";
// import HeroBunny_svg from "../../assets/Images/hero_bunny.svg";
// import HeroBunny_png from "../../assets/Images/hero_bunny.png";

import { CloseOutlined, MenuOutlined } from "@mui/icons-material";

const Navbar = ({ legal }) => {
  const [appLink, setAppLink] = useState("")

  useEffect(() => {

    setAppLink(getPlatformLink())

  }, []);

  const getPlatformLink = () => {
    const userAgent = navigator.userAgent;

    if (userAgent.match(/Android/i)) {
      // return 'https://play.google.com/store/apps/details?id=com.hushbunny.app';
      return 'https://apps.apple.com/app/id1633444164';

    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      return 'https://apps.apple.com/app/id1633444164';
    } else {
      // Set a default link or handle the case accordingly
      return 'https://hushbunny.page.link/app';
    }

  }
  //state to store burger menu
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <div className="navbar_container">
        <section className="navbar_wrapper">
          <div className="navbar_left">
            <div className="nav_left_logo">
              <picture
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                <source type="image/svg+xml" srcSet={navLogo_svg} />
                <source type="image/png" srcSet={navLogo_png} />
                <img src={navLogo_png} alt="nav-logo" />
              </picture>
            </div>

            <div
              className={`nav_left_menu ${menuOpen ? "mobile" : ""} ${legal ? "legal" : ""
                }`}
            >
              <span>
                <Link
                  to="aboutUs"
                  onClick={() => {
                    setMenuOpen(false);
                  }}
                >
                  About
                </Link>
              </span>
              <span>
                <Link
                  to="features"
                  onClick={() => {
                    setMenuOpen(false);
                  }}
                >
                  Features
                </Link>
              </span>
              <span>
                <Link
                  to="screenshots"
                  onClick={() => {
                    setMenuOpen(false);
                  }}
                >
                  App Screenshots
                </Link>
              </span>
            </div>
          </div>
          <div className="navbar_right">
            <div className="nav_right_code">
              <picture>
                <source type="image/webp" srcSet={navCode_webpg} />
                <source type="image/svg+xml" srcSet={navCode_svg} />
                <source type="image/png" srcSet={navCode_png} />
                <img src={navCode_png} alt="nav-code" />
              </picture>

              <div className="nav_right_large_code">
                <div className="nav_right_large_code_text">
                  Scan to download <br /> the app
                </div>
                <picture>
                  <source type="image/webp" srcSet={navCodeLarge_webpg} />
                  <source type="image/svg+xml" srcSet={navCodeLarge_svg} />
                  <source type="image/png" srcSet={navCodeLarge_png} />
                  <img src={navCodeLarge_png} alt="nav-code-large" />
                </picture>
              </div>
            </div>

            <div className="nav_right_btn">
              <a
                // href="https://hushbunny.com"
                // href="https://hushbunny.page.link/app"
                href={appLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>Get Hushbunny</button>
              </a>
            </div>
            <div className="nav_right_menu_icon">
              <span
                className={`navbar_mobile_btn ${menuOpen ? "active" : ""}`}
                onClick={() => {
                  setMenuOpen(!menuOpen);
                }}
              >
                <CloseOutlined />
              </span>
              <span
                className={`navbar_mobile_btn ${menuOpen ? "" : "active"}`}
                onClick={() => {
                  setMenuOpen(!menuOpen);
                }}
              >
                <MenuOutlined />
              </span>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Navbar;
