import React from "react";
import { NavLink } from "react-router-dom";

//images
import footer_logo_webp from "../../assets/Images/hushbunny_footer_logo.webp";
import footer_logo_svg from "../../assets/Images/hushbunny_footer_logo.svg";
import footer_logo_png from "../../assets/Images/hushbunny_footer_logo.png";

import facebook_webp from "../../assets/Images/facebook.webp";
import facebook_svg from "../../assets/Images/facebook.svg";
import facebook_png from "../../assets/Images/facebook.png";
import twitter_webp from "../../assets/Images/twitter.webp";
import twitter_svg from "../../assets/Images/twitter.svg";
import twitter_png from "../../assets/Images/twitter.png";
import instagram_webp from "../../assets/Images/instagram.webp";
import instagram_svg from "../../assets/Images/instagram.svg";
import instagram_png from "../../assets/Images/instagram.png";
// import mail_webp from "../../assets/Images/mail.webp";
// import mail_svg from "../../assets/Images/mail.svg";
// import mail_png from "../../assets/Images/mail.png";

//icons
import { EmailOutlined, Favorite } from "@mui/icons-material";

//function to goto top
const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};


const Footer = () => {
  return (
    <>
      <div className="footer_container">
        <div className="footer_wrapper">
          <div className="footer_top">
            <div className="footer_top_one">
              <div className="footer_top_one_img">
                <picture>
                  <source type="image/webp" srcSet={footer_logo_webp} />
                  <source type="image/svg+xml" srcSet={footer_logo_svg} />
                  <source type="image/png" srcSet={footer_logo_png} />
                  <img src={footer_logo_png} alt="footer-logo" />
                </picture>
              </div>
              <div className="footer_top_one_info">
                Building memory lanes of your kids
              </div>
            </div>
            <div className="footer_top_two">
              <div className="footer_top_two_title">Useful Links</div>
              <div className="footer_top_two_links" onClick={goToTop}>
                <a
                  href="https://privacy.hushbunny.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >Privacy Policy</a>
              </div>
              <div className="footer_top_two_links" onClick={goToTop}>
                <a
                  href="https://terms.hushbunny.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >Terms of use</a>
              </div>
            </div>
            <div className="footer_top_three">
              <div className="footer_top_three_title">Get in Touch</div>
              <div className="footer_top_three_link">
                <span>
                  <EmailOutlined />
                </span>
                <a href="mailto:contact@hushbunny.com">contact@hushbunny.com</a>
              </div>
            </div>
            <div className="footer_top_four">
              <a
                href="https://www.facebook.com/hushbunnyapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="footer_social_icon">
                  <picture>
                    <source type="image/webp" srcSet={facebook_webp} />
                    <source type="image/svg+xml" srcSet={facebook_svg} />
                    <source type="image/png" srcSet={facebook_png} />
                    <img src={facebook_png} alt="facebook-icon" />
                  </picture>
                </span>
              </a>
              <a
                href="https://twitter.com/hushbunnyapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="footer_social_icon">
                  <picture>
                    <source type="image/webp" srcSet={twitter_webp} />
                    <source type="image/svg+xml" srcSet={twitter_svg} />
                    <source type="image/png" srcSet={twitter_png} />
                    <img src={twitter_png} alt="twitter-icon" />
                  </picture>
                </span>
              </a>
              <a
                href="https://www.instagram.com/hushbunnyapp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="footer_social_icon">
                  <picture>
                    <source type="image/webp" srcSet={instagram_webp} />
                    <source type="image/svg+xml" srcSet={instagram_svg} />
                    <source type="image/png" srcSet={instagram_png} />
                    <img src={instagram_png} alt="instagram-icon" />
                  </picture>
                </span>
              </a>
            </div>
          </div>
          <div className="footer_middle">
            <div className="footer_reserved_text">
              <span>&copy;</span>  {new Date().getFullYear()} - 2024 Hushbunny.
              All Rights Reserved.
            </div>
          </div>
          {/* <div className="footer_bottom">
            <a
              href="https://codecraftcrew.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Crafted with
              <span>
                <Favorite />
              </span>
              by Code Craft Crew
            </a>
          </div> */}


        </div>
      </div>
    </>
  );
};

export default Footer;
