import { ActionTypes } from "../constants/ActionTypes";

const initialState = {
  isAuth: window.localStorage.getItem("userAuth"),
  userType: window.localStorage.getItem("userType"),
  authToken: window.localStorage.getItem("authToken"),
};

export const userAuthReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_USER_AUTH:
      return { ...state, isAuth: payload };

    case ActionTypes.LOGOUT_USER:
      return { ...state, isAuth: payload };

    default:
      return state;
  }
};
