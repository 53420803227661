import React from "react";

//component
import Navbar from "../Navbar/Navbar";

//images
import app_store_webp from "../../assets/Images/app_store_button.webp";
import app_store_svg from "../../assets/Images/app_store_button.svg";
import app_store_png from "../../assets/Images/app_store_button.png";
import play_store_webp from "../../assets/Images/play_store_button.webp";
import play_store_svg from "../../assets/Images/play_store_button.svg";
import play_store_png from "../../assets/Images/play_store_button.png";
import banner_img_webp from "../../assets/Images/Banner-img.webp";
import banner_img_svg from "../../assets/Images/Banner-img.svg";
import banner_img_png from "../../assets/Images/Banner-img.png";
import HeroBunny_webp from "../../assets/Images/hero_bunny.webp";
import HeroBunny_svg from "../../assets/Images/hero_bunny.svg";
import HeroBunny_png from "../../assets/Images/hero_bunny.png";

const Hero = () => {
  return (
    <>
      <div className="hero_container">
        <Navbar />
        <section className="hero_wrapper">
          <div className="hero_body">
            <div className="hero_body_left">
              <div className="hero_body_left_title">
                Hush up <br /> all the noise
              </div>
              <div className="hero_body_left_subtitles">
                <div className="hero_body_left_subtitle">
                  Hopping from social networks to video streaming services to
                  instant chat messengers all day?
                </div>
                <div className="hero_body_left_subtitle">
                  It’s time to hush up all the noise and focus on the things
                  that matter- moments of your kids.
                </div>
              </div>
              <div className="download_btns">
                <a
                  href="https://apps.apple.com/app/id1633444164"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="app_store">
                    <div className="download_icon">
                      <picture>
                        <source type="image/webp" srcSet={app_store_webp} />
                        <source type="image/svg+xml" srcSet={app_store_svg} />
                        <source type="image/png" srcSet={app_store_png} />
                        <img src={app_store_png} alt="app-store-logo" />
                      </picture>
                    </div>
                  </button>
                </a>
                {/* <a
                  href="https://play.google.com/store/apps/details?id=com.hushbunny.app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="app_store">
                    <div className="download_icon">
                      <picture>
                        <source type="image/webp" srcSet={play_store_webp} />
                        <source type="image/svg+xml" srcSet={play_store_svg} />
                        <source type="image/png" srcSet={play_store_png} />
                        <img src={play_store_png} alt="play-store-logo" />
                      </picture>
                    </div>
                  </button>
                </a> */}
              </div>
            </div>
            <div className="hero_body_right">
              <div className="hero_right_banner">
                <picture>
                  <source type="image/webp" srcSet={banner_img_webp} />
                  <source type="image/svg+xml" srcSet={banner_img_svg} />
                  <source type="image/png" srcSet={banner_img_png} />
                  <img src={banner_img_png} alt="hero-banner" />
                </picture>
              </div>
            </div>
          </div>
        </section>
        <section className="hero_body_bunny_wrapper">
          <div className="hero_body_bunny_main">
            <div className="hero_body_bunny_img">
              <picture>
                <source type="image/webp" srcSet={HeroBunny_webp} />
                <source type="image/svg+xml" srcSet={HeroBunny_svg} />
                <source type="image/png" srcSet={HeroBunny_png} />
                <img src={HeroBunny_png} alt="hero-bunny" />
              </picture>
            </div>
            <div className="hero_body_bunny_box">
              <div className="hero_body_bunny_box_title">
                Welcome to Hushbunny.
              </div>
              <div className="hero_body_bunny_box_subtitle">
                Add moments and build memory lanes of your kids
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Hero;
