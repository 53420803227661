import React from "react";
import axios from "axios";
import "./OtpModel.css";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useState } from "react";
import Loader from "../Loader/Loader";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/actions/userAuthActions";
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Delete URL
// import { DeleteUserAdminURL, DeleteKidAdminURL } from "../../config/api";


const OtpInput = (props) => {
    const [OTP, setOTP] = useState("");
    const {
        setShowModal,
        memberType,
        memberID,
        modalTitle,
        firstBtn,
        secondBtn, setUsersLoader
    } = props;
    //dispatcher
    const dispatch = useDispatch();

    // Custom styles for the OTP input
    const inputStyles = {
        width: "3rem", // Adjust the width as needed
        height: "3rem", // Adjust the height as needed
        fontSize: "1.5rem", // Adjust the font size as needed
        margin: "0 0.5rem", // Adjust the margin as needed
    };

    //delete method
    const handleDeleteMember = async () => {

        setUsersLoader(true)
        const BaseUrl = "https://api-prod.hushbunny.com/user/v1/verifyDeleteProfileOtp"
        axios
            .put(BaseUrl, { otp: OTP })
            .then((res) => {
                handleDeleteAction()
            })
            .catch((err) => {
                console.log("Sign-in Error :", err);
                setUsersLoader(false)
                setShowModal(false)
                toast.warn(err.response.data.message);
            });
    };

    const handleDeleteAction = async () => {
        const BaseUrl = "https://api-prod.hushbunny.com/session/v1/settingActions/DELETE"
        axios
            .delete(BaseUrl, {})
            .then((res) => {
                toast("Account has been deleted successfully.")
                alert("Account has been deleted successfully.")
                setShowModal(false)
                setUsersLoader(false)
                dispatch(
                    logoutUser(
                        window.localStorage.removeItem("userAuth"),
                        window.localStorage.removeItem("authToken"),
                        window.localStorage.removeItem("signInData")
                    )
                );
                <Navigate to="/" />
            })
            .catch((err) => {
                console.log("Sign-in Error :", err);
                setShowModal(false)
                setUsersLoader(false)
                toast.warn(err.response.data.message);
            });
    }
    return (
        <>
            <div className="modal_container">
                <div className="modal_wrapper">
                    <div className="modal_title">
                        <h4> {modalTitle}</h4>
                    </div>
                    <OTPInput
                        value={OTP}
                        onChange={setOTP}
                        autoFocus
                        OTPLength={4}
                        otpType="number"
                        disabled={false}
                        inputStyles={inputStyles}
                    // secure
                    />
                    {/* <ResendOTP handelResendClick={() => console.log("Resend clicked")} /> */}
                    <div className="modal_buttons">
                        <button
                            className="modal_btn delete"
                            onClick={() => handleDeleteMember()}
                        >
                            {firstBtn}
                        </button>
                        <button
                            className="modal_btn cancel"
                            onClick={() => setShowModal(false)}
                        >
                            {secondBtn}
                        </button>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default OtpInput;
